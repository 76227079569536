html,
body {
  min-height: 100%; }

body {
  background: $gradient;
  font-family: "Montserrat", sans-serif !important;
  min-height: 100vh; }

.out {
  min-height: 100vh; }
.body {
  h1 {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.12;
    color: #ffffff;
    @include r(576) {
      font-size: 30px;
      text-align: center; } }
  h2 {
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 30px;
    @include r(576) {
      font-size: 22px; } }
  .button {
    width: 100%;
    padding: 15px;
    border-radius: 3px;
    background-color: #1c73df;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.2px;
    text-align: center;
    color: #fff;
    &--inline {
      width: auto; }
    &--hollow {
      position: relative;
      padding: 10px 15px;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.23;
      letter-spacing: -0.1px;
      color: rgba(26,44,195,0.7);
      background: rgba(26,44,195,0.1);
      padding-right: 45px;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 15px;
        width: 17px;
        height: 15px;
        background: url('../img/arrow-right.svg') no-repeat; }
      &.button:hover {
        background-color: rgba(26,44,195,0.2); } }

    &:hover {
      background-color: darken(#1c73df, 5%); } }
  blockquote {
    border-left: .25rem solid #eceeef;
    padding: .5rem 1rem; } }
.back {
  position: fixed;
  top: 60px;
  left: 45px;
  fill: #ccc;
  transition: all .3s linear;
  cursor: pointer;
  z-index: 10;
  &:hover {
    fill: #212529;
    transform: rotate(360deg); } }
