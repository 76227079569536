.content {
  flex: 1;
  padding-bottom: 95px;
  @include r(576) {
    background: #fff; }
  h1 {
    margin-top: 30px;
    margin-bottom: 60px;
    font-size: 36px;
    font-weight: bold;
    color: #246489; }
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #246489;
    margin-bottom: 30px; }
  .container {
    padding: 45px 75px 30px;
    padding-bottom: 30px;
    padding-top: 55px;
    background: #fff;
    border-radius: 10px; }
  .info {
    margin-bottom: 45px; }
  .info__text {
    color: #212529; }
  .project {
    margin-bottom: 55px; }
  .project__itemList {
    margin-bottom: 23px;
    a {
      display: inline-block;
      color: #1a2cc3;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.0;
      letter-spacing: -0.1px;
      &:hover {
        text-decoration: none;
        color: lighten(#1a2cc3, 25%); } } } }
