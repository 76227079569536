
$animation-speed: 4.5s;
$animation-deficit: -0.89;
$animation-curve: cubic-bezier(0.42, 0, 0.58, 1);
$nucleodide-color: #fff;
$second-nucleodide-color: #2c27b8;
$nucleodide-size: 2vh;

.dna {
  padding: 70px 0;
  text-align: center;
  @include rmin(768) {
    padding: 130px 0;
    text-align: inherit;
    white-space: nowrap;
    overflow: hidden; } }

.nucleodide {
    position: relative;
    // width: $nucleodide-size*2
    width: 6vw;
    // height: $nucleodide-size*4
    height: 10vh;
    display: inline-block;
    @include rmin(768) {
      width: 2.1vw;
      @for $i from 1 through 45 {
        &:nth-child(#{$i}) {
          & span,
          &:before,
          &:after {
            animation-delay: $i*($animation-speed*$animation-deficit); } } } }
    @include r(767) {
      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          & span,
          &:before,
          &:after {
            animation-delay: $i*($animation-speed*$animation-deficit); } } } } }

.nucleodide:before,
.nucleodide:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -($nucleodide-size/2);
    width: $nucleodide-size;
    height: $nucleodide-size;
    background: $nucleodide-color;
    border-radius: 50%; }


.nucleodide:before {
    animation: nucleodideBefore $animation-speed $animation-curve infinite; }


.nucleodide:after {
    animation: nucleodideAfter $animation-speed $animation-curve infinite; }


.nucleodide:after {
    top: 100%;
    background: $second-nucleodide-color; }


.nucleodide span {
    position: absolute;
    top: 7px;
    bottom: -15px;
    left: 50%;
    width: 1px;
    z-index: -2;
    background: -webkit-linear-gradient(top, $nucleodide-color 0%, $second-nucleodide-color 100%);
    animation: nucleodideBar $animation-speed $animation-curve infinite; }


@keyframes nucleodideBefore {
    0%,
    100% {
        top: 0;
        transform: scale(1); }

    25% {
        transform: scale(1.3);
        background: lighten($nucleodide-color, 10%); }

    50% {
        top: 100%;
        transform: scale(1); }

    75% {
        transform: scale(.7);
        background: darken($nucleodide-color, 10%); } }



@keyframes nucleodideAfter {
    0%,
    100% {
        top: 100%;
        transform: scale(1); }

    25% {
        transform: scale(.7);
        background: darken($second-nucleodide-color, 10%);
        z-index: -1; }

    50% {
        top: 0%;
        transform: scale(1); }

    75% {
        transform: scale(1.3);
        background: lighten($second-nucleodide-color, 10%);
        z-index: 1; } }



@keyframes nucleodideBar {
    0%,
    50%,
    100% {
        top: $nucleodide-size/2;
        bottom: -$nucleodide-size; }

    25%,
    75% {
        top: 50%;
        bottom: 50%; } }


