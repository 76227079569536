.footer {
  background: #0a0d24;
  padding: 40px 0;
  &__form {
    margin-bottom: 70px;
    input {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.1);
      border: solid 1px #979797;
      font-size: 14px;
      padding: 13px 20px;
      color: #fff;
      &::placeholder {
        color: #fff; }
      &[placeholder="Сообщение"] {
        margin-bottom: 0; } } }
  &__login {
    position: relative;
    border-radius: 3px;
    padding-left: 25px;
    border: solid 1px rgba(151,151,151,0.4);
    padding: 10px 20px;
    padding-left: 40px;
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.2px;
    color: rgba(255,255,255,0.6);
    &:hover {
      color: rgba(255,255,255,0.6);
      text-decoration: none;
      border: solid 1px rgba(151,151,151,0.9); }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translate(0, -50%);
      background: url('../img/key.svg') no-repeat;
      width: 12px;
      height: 14px; } }
  h2 {
    margin-bottom: 25px; }
  .info {
    margin-bottom: 30px; } }
