.social {
  &__list {
    margin-bottom: 0; }
  li.social__item {
    margin-right: 15px;
    a {
      display: block;
      position: relative;
      width: 64px;
      height: 64px;
      background: #fff;
      border-radius: 100px;
      transition: all 0.3s;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      &:hover {
        transform: translate(0, -10%); } }
    &:last-child {
      margin-right: 0; } } }
