@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: #000;
}

html,
body {
  min-height: 100%;
}

body {
  background: linear-gradient(125deg, #84e4e3, #1c73df);
  font-family: "Montserrat", sans-serif !important;
  min-height: 100vh;
}

.out {
  min-height: 100vh;
}

.body h1 {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.12;
  color: #ffffff;
}

@media only screen and (max-width: 576px) {
  .body h1 {
    font-size: 30px;
    text-align: center;
  }
}

.body h2 {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
}

@media only screen and (max-width: 576px) {
  .body h2 {
    font-size: 22px;
  }
}

.body .button {
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  background-color: #1c73df;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.2px;
  text-align: center;
  color: #fff;
}

.body .button--inline {
  width: auto;
}

.body .button--hollow {
  position: relative;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: -0.1px;
  color: rgba(26, 44, 195, 0.7);
  background: rgba(26, 44, 195, 0.1);
  padding-right: 45px;
}

.body .button--hollow::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 15px;
  width: 17px;
  height: 15px;
  background: url("../img/arrow-right.svg") no-repeat;
}

.body .button--hollow.button:hover {
  background-color: rgba(26, 44, 195, 0.2);
}

.body .button:hover {
  background-color: #1967c8;
}

.body blockquote {
  border-left: .25rem solid #eceeef;
  padding: .5rem 1rem;
}

.back {
  position: fixed;
  top: 60px;
  left: 45px;
  fill: #ccc;
  transition: all .3s linear;
  cursor: pointer;
  z-index: 10;
}

.back:hover {
  fill: #212529;
  transform: rotate(360deg);
}

.header {
  padding-top: 60px;
}

.dna {
  padding: 70px 0;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .dna {
    padding: 130px 0;
    text-align: inherit;
    white-space: nowrap;
    overflow: hidden;
  }
}

.nucleodide {
  position: relative;
  width: 6vw;
  height: 10vh;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .nucleodide {
    width: 2.1vw;
  }
  .nucleodide:nth-child(1) span, .nucleodide:nth-child(1):before, .nucleodide:nth-child(1):after {
    animation-delay: -4.005s;
  }
  .nucleodide:nth-child(2) span, .nucleodide:nth-child(2):before, .nucleodide:nth-child(2):after {
    animation-delay: -8.01s;
  }
  .nucleodide:nth-child(3) span, .nucleodide:nth-child(3):before, .nucleodide:nth-child(3):after {
    animation-delay: -12.015s;
  }
  .nucleodide:nth-child(4) span, .nucleodide:nth-child(4):before, .nucleodide:nth-child(4):after {
    animation-delay: -16.02s;
  }
  .nucleodide:nth-child(5) span, .nucleodide:nth-child(5):before, .nucleodide:nth-child(5):after {
    animation-delay: -20.025s;
  }
  .nucleodide:nth-child(6) span, .nucleodide:nth-child(6):before, .nucleodide:nth-child(6):after {
    animation-delay: -24.03s;
  }
  .nucleodide:nth-child(7) span, .nucleodide:nth-child(7):before, .nucleodide:nth-child(7):after {
    animation-delay: -28.035s;
  }
  .nucleodide:nth-child(8) span, .nucleodide:nth-child(8):before, .nucleodide:nth-child(8):after {
    animation-delay: -32.04s;
  }
  .nucleodide:nth-child(9) span, .nucleodide:nth-child(9):before, .nucleodide:nth-child(9):after {
    animation-delay: -36.045s;
  }
  .nucleodide:nth-child(10) span, .nucleodide:nth-child(10):before, .nucleodide:nth-child(10):after {
    animation-delay: -40.05s;
  }
  .nucleodide:nth-child(11) span, .nucleodide:nth-child(11):before, .nucleodide:nth-child(11):after {
    animation-delay: -44.055s;
  }
  .nucleodide:nth-child(12) span, .nucleodide:nth-child(12):before, .nucleodide:nth-child(12):after {
    animation-delay: -48.06s;
  }
  .nucleodide:nth-child(13) span, .nucleodide:nth-child(13):before, .nucleodide:nth-child(13):after {
    animation-delay: -52.065s;
  }
  .nucleodide:nth-child(14) span, .nucleodide:nth-child(14):before, .nucleodide:nth-child(14):after {
    animation-delay: -56.07s;
  }
  .nucleodide:nth-child(15) span, .nucleodide:nth-child(15):before, .nucleodide:nth-child(15):after {
    animation-delay: -60.075s;
  }
  .nucleodide:nth-child(16) span, .nucleodide:nth-child(16):before, .nucleodide:nth-child(16):after {
    animation-delay: -64.08s;
  }
  .nucleodide:nth-child(17) span, .nucleodide:nth-child(17):before, .nucleodide:nth-child(17):after {
    animation-delay: -68.085s;
  }
  .nucleodide:nth-child(18) span, .nucleodide:nth-child(18):before, .nucleodide:nth-child(18):after {
    animation-delay: -72.09s;
  }
  .nucleodide:nth-child(19) span, .nucleodide:nth-child(19):before, .nucleodide:nth-child(19):after {
    animation-delay: -76.095s;
  }
  .nucleodide:nth-child(20) span, .nucleodide:nth-child(20):before, .nucleodide:nth-child(20):after {
    animation-delay: -80.1s;
  }
  .nucleodide:nth-child(21) span, .nucleodide:nth-child(21):before, .nucleodide:nth-child(21):after {
    animation-delay: -84.105s;
  }
  .nucleodide:nth-child(22) span, .nucleodide:nth-child(22):before, .nucleodide:nth-child(22):after {
    animation-delay: -88.11s;
  }
  .nucleodide:nth-child(23) span, .nucleodide:nth-child(23):before, .nucleodide:nth-child(23):after {
    animation-delay: -92.115s;
  }
  .nucleodide:nth-child(24) span, .nucleodide:nth-child(24):before, .nucleodide:nth-child(24):after {
    animation-delay: -96.12s;
  }
  .nucleodide:nth-child(25) span, .nucleodide:nth-child(25):before, .nucleodide:nth-child(25):after {
    animation-delay: -100.125s;
  }
  .nucleodide:nth-child(26) span, .nucleodide:nth-child(26):before, .nucleodide:nth-child(26):after {
    animation-delay: -104.13s;
  }
  .nucleodide:nth-child(27) span, .nucleodide:nth-child(27):before, .nucleodide:nth-child(27):after {
    animation-delay: -108.135s;
  }
  .nucleodide:nth-child(28) span, .nucleodide:nth-child(28):before, .nucleodide:nth-child(28):after {
    animation-delay: -112.14s;
  }
  .nucleodide:nth-child(29) span, .nucleodide:nth-child(29):before, .nucleodide:nth-child(29):after {
    animation-delay: -116.145s;
  }
  .nucleodide:nth-child(30) span, .nucleodide:nth-child(30):before, .nucleodide:nth-child(30):after {
    animation-delay: -120.15s;
  }
  .nucleodide:nth-child(31) span, .nucleodide:nth-child(31):before, .nucleodide:nth-child(31):after {
    animation-delay: -124.155s;
  }
  .nucleodide:nth-child(32) span, .nucleodide:nth-child(32):before, .nucleodide:nth-child(32):after {
    animation-delay: -128.16s;
  }
  .nucleodide:nth-child(33) span, .nucleodide:nth-child(33):before, .nucleodide:nth-child(33):after {
    animation-delay: -132.165s;
  }
  .nucleodide:nth-child(34) span, .nucleodide:nth-child(34):before, .nucleodide:nth-child(34):after {
    animation-delay: -136.17s;
  }
  .nucleodide:nth-child(35) span, .nucleodide:nth-child(35):before, .nucleodide:nth-child(35):after {
    animation-delay: -140.175s;
  }
  .nucleodide:nth-child(36) span, .nucleodide:nth-child(36):before, .nucleodide:nth-child(36):after {
    animation-delay: -144.18s;
  }
  .nucleodide:nth-child(37) span, .nucleodide:nth-child(37):before, .nucleodide:nth-child(37):after {
    animation-delay: -148.185s;
  }
  .nucleodide:nth-child(38) span, .nucleodide:nth-child(38):before, .nucleodide:nth-child(38):after {
    animation-delay: -152.19s;
  }
  .nucleodide:nth-child(39) span, .nucleodide:nth-child(39):before, .nucleodide:nth-child(39):after {
    animation-delay: -156.195s;
  }
  .nucleodide:nth-child(40) span, .nucleodide:nth-child(40):before, .nucleodide:nth-child(40):after {
    animation-delay: -160.2s;
  }
  .nucleodide:nth-child(41) span, .nucleodide:nth-child(41):before, .nucleodide:nth-child(41):after {
    animation-delay: -164.205s;
  }
  .nucleodide:nth-child(42) span, .nucleodide:nth-child(42):before, .nucleodide:nth-child(42):after {
    animation-delay: -168.21s;
  }
  .nucleodide:nth-child(43) span, .nucleodide:nth-child(43):before, .nucleodide:nth-child(43):after {
    animation-delay: -172.215s;
  }
  .nucleodide:nth-child(44) span, .nucleodide:nth-child(44):before, .nucleodide:nth-child(44):after {
    animation-delay: -176.22s;
  }
  .nucleodide:nth-child(45) span, .nucleodide:nth-child(45):before, .nucleodide:nth-child(45):after {
    animation-delay: -180.225s;
  }
}

@media only screen and (max-width: 767px) {
  .nucleodide:nth-child(1) span, .nucleodide:nth-child(1):before, .nucleodide:nth-child(1):after {
    animation-delay: -4.005s;
  }
  .nucleodide:nth-child(2) span, .nucleodide:nth-child(2):before, .nucleodide:nth-child(2):after {
    animation-delay: -8.01s;
  }
  .nucleodide:nth-child(3) span, .nucleodide:nth-child(3):before, .nucleodide:nth-child(3):after {
    animation-delay: -12.015s;
  }
  .nucleodide:nth-child(4) span, .nucleodide:nth-child(4):before, .nucleodide:nth-child(4):after {
    animation-delay: -16.02s;
  }
  .nucleodide:nth-child(5) span, .nucleodide:nth-child(5):before, .nucleodide:nth-child(5):after {
    animation-delay: -20.025s;
  }
  .nucleodide:nth-child(6) span, .nucleodide:nth-child(6):before, .nucleodide:nth-child(6):after {
    animation-delay: -24.03s;
  }
  .nucleodide:nth-child(7) span, .nucleodide:nth-child(7):before, .nucleodide:nth-child(7):after {
    animation-delay: -28.035s;
  }
  .nucleodide:nth-child(8) span, .nucleodide:nth-child(8):before, .nucleodide:nth-child(8):after {
    animation-delay: -32.04s;
  }
  .nucleodide:nth-child(9) span, .nucleodide:nth-child(9):before, .nucleodide:nth-child(9):after {
    animation-delay: -36.045s;
  }
  .nucleodide:nth-child(10) span, .nucleodide:nth-child(10):before, .nucleodide:nth-child(10):after {
    animation-delay: -40.05s;
  }
  .nucleodide:nth-child(11) span, .nucleodide:nth-child(11):before, .nucleodide:nth-child(11):after {
    animation-delay: -44.055s;
  }
  .nucleodide:nth-child(12) span, .nucleodide:nth-child(12):before, .nucleodide:nth-child(12):after {
    animation-delay: -48.06s;
  }
}

.nucleodide:before,
.nucleodide:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1vh;
  width: 2vh;
  height: 2vh;
  background: #fff;
  border-radius: 50%;
}

.nucleodide:before {
  animation: nucleodideBefore 4.5s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

.nucleodide:after {
  animation: nucleodideAfter 4.5s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

.nucleodide:after {
  top: 100%;
  background: #2c27b8;
}

.nucleodide span {
  position: absolute;
  top: 7px;
  bottom: -15px;
  left: 50%;
  width: 1px;
  z-index: -2;
  background: -webkit-linear-gradient(top, #fff 0%, #2c27b8 100%);
  animation: nucleodideBar 4.5s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

@keyframes nucleodideBefore {
  0%,
  100% {
    top: 0;
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
    background: white;
  }
  50% {
    top: 100%;
    transform: scale(1);
  }
  75% {
    transform: scale(0.7);
    background: #e6e6e6;
  }
}

@keyframes nucleodideAfter {
  0%,
  100% {
    top: 100%;
    transform: scale(1);
  }
  25% {
    transform: scale(0.7);
    background: #221e8e;
    z-index: -1;
  }
  50% {
    top: 0%;
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
    background: #423cd6;
    z-index: 1;
  }
}

@keyframes nucleodideBar {
  0%,
  50%,
  100% {
    top: 1vh;
    bottom: -2vh;
  }
  25%,
  75% {
    top: 50%;
    bottom: 50%;
  }
}

.directions {
  margin-bottom: 110px;
}

.directions__item {
  padding: 40px 30px;
  background: #fff;
  margin-bottom: 30px;
  border-radius: 10px;
  min-height: 255px;
  box-shadow: 0 10px 20px 0 rgba(47, 87, 143, 0.1), 0 2px 4px 0 rgba(78, 101, 191, 0.15);
  transition: all .18s linear;
}

.directions__item:hover {
  box-shadow: 0 40px 40px 0 rgba(47, 87, 143, 0.1), 0 5px 6px 0 rgba(78, 101, 191, 0.15);
}

.directions img {
  margin-bottom: 20px;
}

.directions__title {
  font-size: 28px;
  line-height: 1.1;
  font-weight: bold;
  color: #246489;
  margin-bottom: 15px;
}

@media only screen and (max-width: 576px) {
  .directions__title {
    font-size: 24px;
  }
}

.directions__descr {
  font-size: 14px;
  line-height: 1.14;
  color: rgba(12, 16, 42, 0.6);
  padding-bottom: 16px;
  margin-bottom: 17px;
  position: relative;
}

.directions__descr::after {
  content: '';
  display: block;
  position: absolute;
  left: -30px;
  bottom: 0;
  height: 1px;
  width: calc(100% + 60px);
  border-bottom: 1px solid rgba(140, 149, 225, 0.2);
}

@media only screen and (max-width: 576px) {
  .directions__descr {
    font-size: 12px;
  }
}

.directions__list {
  list-style-type: none;
  color: rgba(26, 44, 195, 0.5);
}

.directions__itemList {
  margin-bottom: 8px;
  position: relative;
  padding-left: 19px;
}

.directions__itemList::before {
  content: '';
  position: absolute;
  display: block;
  height: 5px;
  width: 5px;
  border-radius: 100px;
  background: rgba(26, 44, 195, 0.5);
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.directions__itemList a {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: -0.1px;
  color: #1a2cc3;
}

.directions__itemList a:hover {
  text-decoration: none;
  color: #717eec;
}

@media only screen and (max-width: 768px) {
  .directions {
    margin-bottom: 50px;
  }
}

.team {
  margin-bottom: 90px;
}

.team h2.team__title {
  margin-bottom: 50px;
}

.team__item {
  margin-bottom: 50px;
}

.team__img {
  margin-bottom: 5px;
  width: 120px;
  height: 120px;
  border-radius: 100px;
}

.team__name {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #ffffff;
}

.team__position {
  font-size: 13px;
  letter-spacing: 0.2px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 768px) {
  .team {
    margin-bottom: 50px;
  }
}

.contacts {
  margin-bottom: 30px;
}

.contacts p {
  font-size: 14px;
  color: #ffffff;
}

.contacts h2 {
  margin-bottom: 30px;
}

.social__list {
  margin-bottom: 0;
}

.social li.social__item {
  margin-right: 15px;
}

.social li.social__item a {
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #fff;
  border-radius: 100px;
  transition: all 0.3s;
}

.social li.social__item a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social li.social__item a:hover {
  transform: translate(0, -10%);
}

.social li.social__item:last-child {
  margin-right: 0;
}

.info {
  display: flex;
  margin-bottom: 85px;
  max-width: 530px;
}

@media only screen and (max-width: 576px) {
  .info {
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px;
  }
}

.info__img {
  margin-right: 30px;
}

@media only screen and (max-width: 576px) {
  .info__img {
    margin-bottom: 30px;
  }
}

.info__text {
  font-size: 13px;
  line-height: 1.23;
  color: #ffffff;
  font-weight: 500;
}

.info__text span {
  text-transform: uppercase;
}

@media only screen and (max-width: 576px) {
  .info__text {
    text-align: center;
  }
}

.content {
  flex: 1;
  padding-bottom: 95px;
}

@media only screen and (max-width: 576px) {
  .content {
    background: #fff;
  }
}

.content h1 {
  margin-top: 30px;
  margin-bottom: 60px;
  font-size: 36px;
  font-weight: bold;
  color: #246489;
}

.content h2 {
  font-size: 24px;
  font-weight: bold;
  color: #246489;
  margin-bottom: 30px;
}

.content .container {
  padding: 45px 75px 30px;
  padding-bottom: 30px;
  padding-top: 55px;
  background: #fff;
  border-radius: 10px;
}

.content .info {
  margin-bottom: 45px;
}

.content .info__text {
  color: #212529;
}

.content .project {
  margin-bottom: 55px;
}

.content .project__itemList {
  margin-bottom: 23px;
}

.content .project__itemList a {
  display: inline-block;
  color: #1a2cc3;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.0;
  letter-spacing: -0.1px;
}

.content .project__itemList a:hover {
  text-decoration: none;
  color: #717eec;
}

.map {
  font-size: 0;
}

.footer {
  background: #0a0d24;
  padding: 40px 0;
}

.footer__form {
  margin-bottom: 70px;
}

.footer__form input {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  border: solid 1px #979797;
  font-size: 14px;
  padding: 13px 20px;
  color: #fff;
}

.footer__form input::placeholder {
  color: #fff;
}

.footer__form input[placeholder="Сообщение"] {
  margin-bottom: 0;
}

.footer__login {
  position: relative;
  border-radius: 3px;
  padding-left: 25px;
  border: solid 1px rgba(151, 151, 151, 0.4);
  padding: 10px 20px;
  padding-left: 40px;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.6);
}

.footer__login:hover {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  border: solid 1px rgba(151, 151, 151, 0.9);
}

.footer__login::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
  background: url("../img/key.svg") no-repeat;
  width: 12px;
  height: 14px;
}

.footer h2 {
  margin-bottom: 25px;
}

.footer .info {
  margin-bottom: 30px;
}
