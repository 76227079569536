.directions {
  margin-bottom: 110px;
  &__item {
    padding: 40px 30px;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 10px;
    min-height: 255px;
    box-shadow: 0 10px 20px 0 rgba(47, 87, 143, 0.1), 0 2px 4px 0 rgba(78, 101, 191, 0.15);
    transition: all .18s linear;
    &:hover {
      box-shadow: 0 40px 40px 0 rgba(47,87,143,.1), 0 5px 6px 0 rgba(78,101,191,.15); } }
  img {
    margin-bottom: 20px; }
  &__title {
    font-size: 28px;
    line-height: 1.1;
    font-weight: bold;
    color: #246489;
    margin-bottom: 15px;
    @include r(576) {
      font-size: 24px; } }
  &__descr {
    font-size: 14px;
    line-height: 1.14;
    color: rgba(12,16,42,0.6);
    padding-bottom: 16px;
    margin-bottom: 17px;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -30px;
      bottom: 0;
      height: 1px;
      width: calc(100% + 60px);
      border-bottom: 1px solid rgba(140,149,225,0.2); }
    @include r(576) {
      font-size: 12px; } }
  &__list {
    list-style-type: none;
    color: rgba(26,44,195,0.5); }
  &__itemList {
    margin-bottom: 8px;
    position: relative;
    padding-left: 19px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 5px;
      width: 5px;
      border-radius: 100px;
      background: rgba(26,44,195,0.5);
      top: 50%;
      left: 0;
      transform: translate(0, -50%); }
    a {
      display: inline-block;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.23;
      letter-spacing: -0.1px;
      color: #1a2cc3;
      &:hover {
        text-decoration: none;
        color: lighten(#1a2cc3, 25%); } } }

  @include r(768) {
    margin-bottom: 50px; } }
