.info {
  display: flex;
  margin-bottom: 85px;
  max-width: 530px;
  @include r(576) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px; }
  &__img {
    margin-right: 30px;
    @include r(576) {
      margin-bottom: 30px; } }
  &__text {
    font-size: 13px;
    line-height: 1.23;
    color: #ffffff;
    font-weight: 500;
    span {
      text-transform: uppercase; }
    @include r(576) {
      text-align: center; } } }
