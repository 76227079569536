.team {
  margin-bottom: 90px;
  h2.team__title {
    margin-bottom: 50px; }
  &__item {
    margin-bottom: 50px; }
  &__img {
    margin-bottom: 5px;
    width: 120px;
    height: 120px;
    border-radius: 100px; }
  &__name {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: #ffffff; }
  &__position {
    font-size: 13px;
    letter-spacing: 0.2px;
    font-weight: 500;
    color: rgba(255,255,255,0.7); }
  @include r(768) {
    margin-bottom: 50px; } }
